<template>
    <div class="page-contents">
        <component
            :is="globalStore.header.component"
            v-if="globalStore && globalStore.header"
            :global="globalStore.global.content"
            :blok="globalStore.header"
        />
        <main>
            <component :is="story.content.component" v-if="story" :blok="story.content" />
        </main>
        <Component
            :is="globalStore.emailSubscription.component"
            v-if="globalStore && globalStore.emailSubscription"
            :global="globalStore.global.content"
            :blok="globalStore.emailSubscription"
        />
        <Component
            :is="globalStore.footer.component"
            v-if="globalStore && globalStore.footer"
            :global="globalStore.global.content"
            :blok="globalStore.footer"
        />
    </div>
</template>

<script setup lang="ts">
// Constant variable for access to quote store
import { useGlobalStore } from '../store/global';

const globalStore: any = useGlobalStore();

const { locale } = useI18n();

const route: any = useRoute();

// Constant variable for access to Runtime Config
const config = useRuntimeConfig();

// Need to resolve shared components to allow the storyblok API
// to expose this for us to use.
const resolveRelations: string[] = [
    'Page.globals',
    'ImageGridAirports.Airports',
    'ImageGridAirportsV2.Airports',
    'ImageGrid.Airports',
    'Link_Section.entities',
    'PSFAirportPage.globals',
    'PSFAirportPage.AirportEntity',
];

if (config?.public.STORYBLOK_FOLDER === 'l4') {
    resolveRelations.push(
        'L4FooterContainer.footer',
        'L4HeaderContainer.header',
        'L4CustomerReviews.reviewsBlock',
        'L4Testimonial.review_what',
        'L4TrustedPartners.partners',
        'L4Testimonial.review_what',
        'L4CarParkingOptions.products',
        'L4CarParkingOptions.airport'
    );
}

if (config?.public.STORYBLOK_FOLDER === 'sps') {
    resolveRelations.push('SPSAirportPage.globals', 'AddressEntity.Entity');
}

if (config?.public.STORYBLOK_FOLDER === 'jp') {
    resolveRelations.push('CoreLocationPage.globals', 'CoreLocationPage.entity');
}
const sbLocation = `/${config?.public.STORYBLOK_FOLDER}/pages/${route.params && route.params.slug.length > 0 ? route.params.slug.join('/') : 'home'}`;
// Perform request to Storyblok to retrieve content
const story: any = await useAsyncStoryblok(sbLocation, {
    version: config?.public?.STORYBLOK_VERSION === 'published' ? 'published' : 'draft',
    language: locale.value,
    resolve_relations: resolveRelations,
}).then((data) => {
    setSeoMeta(data.value?.content?.seo, config?.public, route);
    if (data.value?.content?.globals) {
        globalStore.update(data.value.content.globals);
    }

    return data;
});

function getStoryblokBlockedRoutes() {
    if (config?.public.STORYBLOK_ROUTE_BLOCKED) {
        const blocked = config?.public.STORYBLOK_ROUTE_BLOCKED.split(', ');
        return blocked.some((block) => route.path.includes(block));
    } else {
        return false;
    }
}

// Send user to error page if no story found
if (getStoryblokBlockedRoutes() || !story.value) {
    console.error('URL Path:', route.fullPath);
    console.error('Storyblok Path:', sbLocation);
    throw createError({
        statusCode: 404,
        statusMessage: 'Page Not Found',
        fatal: true,
    });
}

if (!import.meta.server) {
    // Allow use of Storyblok Bridge (Visual Editor)
    useStoryblokBridge(story.id, (evStory: any) => (story.value = evStory), {
        resolveRelations,
    });
}
</script>
